<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-bot-status" centered hide-footer size="xl" :no-close-on-backdrop="true" content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="bi bi-robot text-primary me-1"></i> Bots Status
        </h5>
      </template>
      <div class="row align-items-top">
        
        <!-- datatable -->
        <div class="col-12 mb-2 d-flex">
          <div>
            <label class="d-inline-flex align-items-center">
              Filter:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Keyword..."
                class="form-control form-control-sm ms-2"
              ></b-form-input>
            </label>
          </div>
          <div class="ms-auto mb-0">
            <div class="form-check form-switch form-check-custom mb-0 me-lg-1 form-control-sm">
              <input class="form-check-input mb-0" type="checkbox" id="liveUpdate" v-model="liveUpdate" @change="updateLiveUpdates">

              <label class="form-check-label mb-0 font-size-14" for="liveUpdate">Live 
                <span class="d-inline-block text-center" style="width: 20px;" v-if="liveUpdate">({{liveUpdateSeconds}}s)</span>
                <span v-else class="d-inline-block text-center">(-)</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
            <div class="table-responsive mb-0">
              <!-- striped -->
                <b-table
                  class="text-nowrap align-middle mb-0 custom-datatable-table"
                  :items="bankAccountList"
                  striped
                  :fields="visibleFields()"
                  responsive="sm"
                  :per-page="perPage2"
                  :current-page="currentPage2"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :busy="loading"
                  show-empty
                >        
                <template>
                  <div class="text-center py-5 text-center text-muted" slot="table-busy">
                    <div
                      class="spinner-border text-secondary my-2"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <br />
                    <div>Loading...</div>
                  </div>
                </template>
                <template slot="empty">
                  <div class="text-center text-muted py-5">
                    <i class="bi bi-robot me-1"></i> No Data Available
                  </div>
                </template>
                <template slot="emptyfiltered">
                  <div class="text-center text-muted py-5">
                    <i class="bi bi-bank me-1"></i> There are no records matching your keyword
                  </div>
                </template>
                <!-- <template #cell(index)="data">
                  {{ data.index + 1 }}
                  Whole Index
                  {{ currentPage2 * perPage2 - perPage2 + data.index }}
                </template>

                <template #cell(name)="data">
                  {{data.value}}
                </template> -->

                <template #cell(bot_id)="data">
                  <span v-if="data.value"> #{{data.value || '-'}}</span>
                  <span v-else>-</span>
                </template>

                <template #cell(bot_status)="data">
                  <span v-if="data.item.bot_status=='unavailable'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Offline</span>
                  <span v-else-if="data.item.bot_status=='available'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Online</span>
                  <span v-else>-</span>

                
                </template>

                <template #cell(bot_last_online)="data">
                  {{data.value || '-'}}
                </template>

               

                <template #cell(upi_accounts)="data">
                  <b-dropdown variant="light" size="sm" :disabled="!data.item.upi_accounts.length">
                      <template v-slot:button-content>
                        <span class="text-primary fw-medium font-size-13">
                          UPI Accounts ({{data.item.upi_accounts.length}})
                        <i class="mdi mdi-chevron-down"></i>
                        </span>
                        
                      </template>
                      <b-dropdown-item
                        class="d-flex align-items-center font-size-13"
                        href="javascript:void(0)"
                        v-for="(v, i) in data.item.upi_accounts"
                        :key="i"
                        > 
                        <i class="bi bi-check-circle text-primary me-2"></i>
                          <span> 
                            {{ v.upi_id }}
                          </span>
                        </b-dropdown-item
                      >
                    </b-dropdown>
                </template>
                
              

                <template #cell(active)="data">
                  <span v-if="data.item.active=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Inactive</span>
                    <span v-else-if="data.item.active=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Active</span>
                </template>


                <template #cell(action)="data">
                    <div v-if="create_modify_permission">
                      <!-- :title="data.item.action === 'available' ? 'Disable Bot' : 'Enable Bot'" -->
                      <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14"
                       v-b-tooltip.hover 
                        v-if="data.item.action!==''">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="data.item.action" true-value="available" false-value="unavailable" :disabled="loading || modalLoading" @change="updateBotStatus(data.item)">
                      </div>
                    </div>
                </template>

                <template #cell(bank_name)="data">
                  {{ data.item.bank_name  }} 
                </template>

                <template #cell(id)="data">
                  #{{ data.item.id }}
                </template>
              </b-table>
            </div>
            
        </div>
        <div class="col-12 mb-0" v-if="bankAccountList.length && !loading">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-end"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  size="sm"
                  v-model="currentPage2"
                  :total-rows="totalData"
                  :per-page="perPage2"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
        <!-- datatable end -->
        <div class="col-12 d-none">
          <hr>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-bot-status'].hide()">Cancel</button>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
import {
  required
} from "vuelidate/lib/validators";
//const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
// const validWebsiteUrl = (value) => {
//   console.log(value);
//   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
//   return regex.test(value);
// }
// const validUsername = (value) => {
//   const regex = /^[a-z0-9]+$/;
//   return regex.test(value);
// };
//  const equalDefault = (value) => {
//   return value !== -1;
//   }
/**
 * Starter page
 */
export default {
  components: {
    Common,
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      updateData: {},
      accessToken: '',
      accessEmail: '',
      accessPhone: '',
      accessUsername: '',
      loading: false,
      disabled: false,
      modalSubmit: false,
      modalLoading: false,
      seletedStatus: "",
      currentImageSelected: "front",
      fileCompressing: false,
      returnData: [],
      selectedBankAccount:[],
      bankAccountList:[],
      totalBankAccountSeletedAmount:0,
      formSuccessData: {
        transactionId: "",
        transactionReceipt: [],
        transactionRemarks: "",
        bankAccount: "",
      },
      formRejectedData: {
        reasonForRejected: "",
      },
      uploadProgress:0,
      currentFiles: 0,
      uploadHowManyPerTime:0,
      maxFiles: 3,
      liveUpdateIntervalId: null,
      liveUpdate:false,
      liveUpdateLoading:false,
      liveUpdateSeconds: 15,
      liveUpdateSecondsId: null,
      //datatable
      totalData:0,
      totalRows: 1,
      currentPage2: 1,
      perPage2: 20,
      filter: "",
      filterOn: [],
      sortBy: "active",
      sortDesc: true,
      fields: [
       
        {
          key: "bank_name",
          label:"Bank Name",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bank_account_no",
          label:"Bank Acc. No",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
       
        // {
        //   key: "bank_account_name",
        //   label:"Bank Acc. Name",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle",
        //   sortable: true,
        // },
        {
          key: "current_bank_balance",
          label:"Bank Balance",
          thClass:"user-select-none",
          tdClass: "align-middle",
          formatter: (value) => {
            if (value) {
              return this.convertCurrencyFormat(value,false);
            } else {
              return 0; // or any default value if upi_accounts is not an array
            }
          },
          sortable: true,
        },
        {
          key: "active",
          label:"Status",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bot_id",
          label:"Bot ID",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bot_status",
          label:"Bot Status",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        {
          key: "bot_last_online",
          label:"Bot Last Online",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        //{
        //   key: "action",
        //   label:"Bot Action",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle text-center",
        //   sortable: false,
        // }
      ]
    };
  },
  validations: {
    formSuccessData: {
      // transactionReceipt: {
      //   required,
      // },
      transactionId: {
        required,
      },
      bankAccount: {
        required,
      },
    },
    formRejectedData: {
      reasonForRejected: {
        required,
      }
    }
  },
  middleware: "authentication",
  async mounted() {
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
    await this.reload();
  },
  created() {},
  methods: {
    updateBotStatus(value){
        this.$Progress.start();
        this.modalLoading = true
        var bodyFormData = new FormData();
        this.liveUpdateSeconds = 15;
        clearInterval(this.liveUpdateIntervalId);
        clearInterval(this.liveUpdateSecondsId);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("botID", value.bot_id);
        bodyFormData.append("status", value.action);
        console.log(value)
        axios({
            method: "post",
            //url: appConfig.APIHostAdmin + 'controller/admin/',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
             
            this.modalLoading = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: '1Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
        this.updateLiveUpdates();
    },
    liveUpdates() {
      this.liveUpdateIntervalId = setInterval(() => {
        this.getBankAccountList();
        this.liveUpdateSeconds = 15
        this.liveUpdateLoading = true;
      }, 16000);

      this.liveUpdateSecondsId = setInterval(() => {
        this.liveUpdateSeconds = this.liveUpdateSeconds-1
        if (this.liveUpdateSeconds<=0){
          this.liveUpdateSeconds = 0
        }
      }, 1000);
    },
    updateLiveUpdates(){
      this.loading = true;
      if (this.liveUpdate){
        this.liveUpdates();
      }else{
        clearInterval(this.liveUpdateIntervalId);
        clearInterval(this.liveUpdateSecondsId);
      }
      console.log(this.liveUpdateIntervalId);
      this.getBankAccountList();
    },
    visibleFields() {
      return this.fields.filter(field => {
      // Include all fields except the "Action" column if the condition is true
      return field.key !== 'action' || this.create_modify_permission;
    });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage2 = 1;
    },
    async onChangePDF(e) {
      console.log(this.currentFiles,this.maxFiles);
      console.log(e.target.files.length);
      if ((e.target.files.length+this.currentFiles)> this.maxFiles) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `The system allows a maximum of ${this.maxFiles} file uploads.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        });
        e.target.value = '';
        return; // Abort further processing if the file limit is exceeded
      }

      // Iterate over each file
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        // Check if the file size is greater than 5MB
        if (file.size > 5 * 1024 * 1024) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `The file ${file.name} exceeds the 5MB size limit.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          e.target.value = '';
          return; // Abort further processing if any file size exceeds 5MB
        }

        // Upload each file
        await this.uploadFile(file, e.target.files.length);
      }
    },
    convertFileSize(fileSize) {
      var formattedFileSize = ''
      if (fileSize < 1024) {
        formattedFileSize = fileSize + ' bytes';
      } else if (fileSize < 1048576) { // 1024 * 1024
        formattedFileSize = (fileSize / 1024).toFixed(2) + ' KB';
      } else if (fileSize < 1073741824) { // 1024 * 1024 * 1024
        formattedFileSize = (fileSize / 1048576).toFixed(2) + ' MB';
      } else {
        formattedFileSize = (fileSize / 1073741824).toFixed(2) + ' GB';
      }
      return formattedFileSize
    },
    async uploadFile(file, howManyFiles) {
      this.$Progress.start();
      this.disabled = true;
      const bodyFormData = new FormData();
      bodyFormData.append('accessToken', this.accessToken);
      bodyFormData.append('accessUsername', this.accessUsername);
      bodyFormData.append('media', file);
      var postUrl = appConfig.APIHostAdmin + 'controller/media/saveMediaFile'
      try {
        const response = await axios.post(postUrl, bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });

        const resData = response.data;
        if (resData.status === 200) {
          // Add the URL to your array or handle the success as needed
          this.formSuccessData.transactionReceipt.push(
            {
              'file_name':file.name,
              'file_size': this.convertFileSize(file.size),
              'link': resData.media_path
            })
          
          this.currentFiles = this.currentFiles + 1
          this.uploadHowManyPerTime = this.uploadHowManyPerTime + 1
          if (howManyFiles==this.uploadHowManyPerTime){
            document.getElementById('formFile').value = ''
            this.uploadHowManyPerTime = 0
          }
        } else if (resData.status === 440) {
          // Handle 440 status code
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
          localStorage.clear();
          this.$router.push({
            path: '/login',
          });
        } else {
          // Handle other status codes
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          });
        }
      } catch (error) {
        // Handle the error
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        });
      } finally {
        this.disabled = false;
        this.uploadProgress = 0;
        this.$Progress.finish();
      }
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.formSuccessData[parentModel] = numericValue;
    },
    inputNumberOnlyV2(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][index][childModel] = numericValue
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    async getBankAccountList(){
        this.bankAccountList = []
        console.log("getBankAccountList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "99999");
        bodyFormData.append("keyword", "");
        bodyFormData.append("sortBy", "status_asc");
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/bankAccount/getAllBankAccountList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total;
              this.selectedBankAccount = []
              this.bankAccountList = []

              this.liveUpdateSeconds = 15
              resData.data.forEach(element => {
                this.bankAccountList.push({
                    account_db_code: element.account_db_code,
                    id: element.id,
                    currency: element.currency,
                    bank_name: element.bank_name,
                    bank_account_name: element.bank_account_name,
                    bank_account_no: element.bank_account_no,
                    ifsc_code: element.ifsc_code,
                    transaction_limit: element.transaction_limit,
                    today_in_amount: element.today_in_amount,
                    today_out_amount: element.today_out_amount,
                    current_bank_balance: element.current_bank_balance,
                    active: element.active,
                    bot_id: element.bot_id,
                    bot_status: element.bot_status,
                    bot_last_online: element.bot_last_online,
                    action: element.bot_status,
                    keyword:`${element.bank_name} - ${element.bank_account_no} (${element.bank_account_name})`
                  });
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    showModal() {
      this.$refs['modal-bot-status'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.loading = true
      this.getBankAccountList();
      this.liveUpdate = true
      this.liveUpdateSeconds = 15
      this.liveUpdates();
    },
    startFiles() {
      this.fileCompressing = true;
    },
    currentImageSelectedFunc(index) {
      console.log("seleted" + index);
      this.currentImageSelected = index;
      if (this.currentImageSelected == 'front') {
        this.$refs.fileInput.trigger();
      } else if (this.currentImageSelected == 'back') {
        this.$refs.fileInput2.trigger();
      }
    },
    viewAllFiles(res) {
      this.fileCompressing = false;
      for (let n of res) {
        // this.ShakeCampaignBanner = n.img;
        const myFile = new File([this.DataURIToBlob(n.img)], n.name, {
          type: "image/jpeg",
        });
        if (this.currentImageSelected == 'front') {
          this.formSuccessData.transactionReceipt = n.img;
          this.formSuccessData.transactionReceiptFile = myFile;
          this.formSuccessData.transactionReceiptFilename = n.name
          console.log(myFile)
        } else if (this.currentImageSelected == 'back') {
          // this.formSuccessData.transactionReceipt2 = n.img;
          // this.formSuccessData.transactionReceiptFile2 = myFile;
          // this.formSuccessData.transactionReceiptFilename2 = n.name
          // console.log(myFile)
        }
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
      return new Blob([ia], {
        type: mimeString
      });
    },
    
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.selectedBankAccount = []
      this.currentPage2 = 1
      this.totalBankAccountSeletedAmount = 0
      this.liveUpdateSeconds = 15
      clearInterval(this.liveUpdateIntervalId);
      clearInterval(this.liveUpdateSecondsId);
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          const permission = data.modules.filter(e => e.name==='administrator')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "administrator" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>